<template>
  <ProcurementsCard />
</template>

<script>
import ProcurementsCard from '@/components/ProcurementsCard';

export default {
  components: {
    ProcurementsCard
  }
};
</script>
