var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"px-4"},[_c('v-card-title',[_vm._v("Marketplace Procurement Requests")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.procurementRequests,"search":_vm.search,"loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('div',{staticClass:"text-center ma-12"},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)])]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mb-1",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"clearable":"","flat":"","hide-details":"","prepend-inner-icon":"search","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-select',{attrs:{"items":_vm.entitlementStates,"item-text":"name","item-value":"value","menu-props":{ maxHeight: '400' },"label":"State","multiple":"","hint":"Procurement State filter","persistent-hint":""},on:{"change":_vm.entitlementStateChanged},model:{value:(_vm.selectedEntitlementStates),callback:function ($$v) {_vm.selectedEntitlementStates=$$v},expression:"selectedEntitlementStates"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.loadProcurementRequests()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.refresh))])],1)],1)]},proxy:true},_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{key:h.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[(header.tooltip)?_c('span',[_vm._v(_vm._s(header.tooltip))]):_vm._e()])]}}}),{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email || item.account)+" ")]}},{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toLocalTime(item.createTime))+" ")]}},{key:"item.updateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toLocalTime(item.updateTime))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(
          item.state === 'ENTITLEMENT_ACTIVATION_REQUESTED' ||
            item.state === 'ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL'
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.rejectItem(item)}}},on),[_vm._v(" "+_vm._s(_vm.icons.cancel)+" ")])]}}],null,true)},[_c('span',[_vm._v("Reject")])]):_vm._e(),(item.state === 'ENTITLEMENT_ACTIVATION_REQUESTED')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"amber"},on:{"click":function($event){return _vm.commentItem(item)}}},on),[_vm._v(" "+_vm._s(_vm.icons.comment)+" ")])]}}],null,true)},[_c('span',[_vm._v("Comment")])]):_vm._e(),(
          item.activated === true &&
            (item.state === 'ENTITLEMENT_ACTIVATION_REQUESTED' ||
              item.state === 'ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL')
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"green"},on:{"click":function($event){return _vm.approveItem(item)}}},on),[_vm._v(" "+_vm._s(_vm.icons.check)+" ")])]}}],null,true)},[_c('span',[_vm._v("Approve")])]):_vm._e()]}}],null,true)}),(_vm.showError)?_c('Dialog',{attrs:{"title":_vm.errorDialogTitle,"text":_vm.errorDialogText,"cancelButtonEnabled":false},on:{"confirmed":function($event){_vm.showError = false}},model:{value:(_vm.showError),callback:function ($$v) {_vm.showError=$$v},expression:"showError"}}):_vm._e(),(_vm.showReviewProcurement)?_c('v-dialog',{attrs:{"persistent":"","max-width":"450"},model:{value:(_vm.showReviewProcurement),callback:function ($$v) {_vm.showReviewProcurement=$$v},expression:"showReviewProcurement"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Review Procurement Request")]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.selectedItemSummary)}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-form',{staticClass:"px-4"},[_c('v-radio-group',{attrs:{"mandatory":false},model:{value:(_vm.approvalDialogData.approvalStatus),callback:function ($$v) {_vm.$set(_vm.approvalDialogData, "approvalStatus", $$v)},expression:"approvalDialogData.approvalStatus"}},[(_vm.selectedItem.state !== 'ENTITLEMENT_CANCELLED')?_c('v-radio',{attrs:{"label":"Reject","value":"reject","color":"red"}}):_vm._e(),(
                _vm.selectedItem.state !== 'ENTITLEMENT_CANCELLED' &&
                  _vm.selectedItem.state !==
                    'ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL'
              )?_c('v-radio',{attrs:{"label":"Comment","value":"comment","color":"amber"}}):_vm._e(),(
                _vm.selectedItem.activated === true &&
                  (_vm.selectedItem.state ===
                    'ENTITLEMENT_ACTIVATION_REQUESTED' ||
                    _vm.selectedItem.state ===
                      'ENTITLEMENT_PENDING_PLAN_CHANGE_APPROVAL')
              )?_c('v-radio',{attrs:{"label":"Approve","value":"approve","color":"green"}}):_vm._e()],1),(
              _vm.approvalDialogData.approvalStatus == 'comment' ||
                _vm.approvalDialogData.approvalStatus == 'reject'
            )?_c('ValidationProvider',{attrs:{"name":"Comment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Comment","required":""},model:{value:(_vm.approvalDialogData.comment),callback:function ($$v) {_vm.$set(_vm.approvalDialogData, "comment", $$v)},expression:"approvalDialogData.comment"}})]}}],null,true)}):_vm._e()],1)]}}],null,false,1826740558)}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeApprovalDialog()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.submitApproval.apply(null, arguments)}}},[_vm._v("Submit")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }